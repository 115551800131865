import React from 'react';
import { Row } from 'reactstrap';
import { colors } from 'config/colors';

const CustomCard = ({color,children, id,modal}) => {

  return (
    <Row id={id} className={`bg-${!modal ? color : ""} h-100 border-radius d-flex align-items-center justify-content-center`} style={{background: modal ? colors?.portalBackground : ""}}>
      {children}
    </Row>
  );
}

export default CustomCard;
