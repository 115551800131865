import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

// ** fetch prices
export const fetchPrices = createAsyncThunk('marketplace/fetchPrices', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/marketplace/price/list?type=${params?.type}${params?.code ? `&code=${params?.code}` : ""}`)
  return response?.data
})

// ** cativation price check
export const cativationPriceCheck = createAsyncThunk('marketplace/cativationPriceCheck', async (params, { getState, dispatch }) => {
  const response = await api.get(`api/marketplace/cativationPrice/list?type=${params}`)
  return response?.data
})

const initialState = {
  packs: [],
}

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: { resetMarketPlaceState: () => initialState },
  extraReducers: builder => {
    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.packs = action.payload.Data
    })
  }
})

export const { resetMarketPlaceState } = marketplaceSlice.actions;
export default marketplaceSlice.reducer

