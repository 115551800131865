export const routes = [
  { 
    path: "/checkout", 
    inativityTime: 10
  },
  { 
    path: "/checkout-summary", 
    inativityTime: 20
  },
  { 
    path: "/collect-number", 
    inativityTime: 10
  },
  { 
    path: "/access-lockers", 
    inativityTime: 10
  },
  { 
    path: "/collect-pin", 
    inativityTime: 10
  },
  { 
    path: "/home", 
    inativityTime: false
  },
  { 
    path: "/open-locker", 
    inativityTime: 10
  },
  { 
    path: "/release-locker", 
    inativityTime: 10
  },
  { 
    path: "/confirm-rental", 
    inativityTime: 10
  }
];

export const modals = [
  { 
    name: "language", 
    inativityTime: 10
  },
  { 
    name: "faqs", 
    inativityTime: 10
  },
  { 
    name: "notifications", 
    inativityTime: 10
  },
  { 
    name: "terms", 
    inativityTime: 10
  }
]
