import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { fetchFaqs, updateFaqsLanguage, updateLoading, updateTermsModal } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import CustomButton from 'components/common/buttons/customButton';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { useDispatch } from 'react-redux';
import CustomCard from '../cards/customCard';
import { ReactComponent as ChevronDownScroll } from 'assets/images/icons/chevronDownScroll.svg'
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import { colors } from 'config/colors';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useNavigate } from 'react-router-dom';

const FaqsModal = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState('');
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const [faqs, setFaqs] = useState(false);
  const [columnScroll, setColumnScroll] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);

  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const navTo = (url, state) => {
    if (state) {
      handleTransition(url, state)
    }
    else {
      handleTransition(url)
    }
  };

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
  }

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  const scrollToTop = () => {
    const accordionElement = document.getElementById('faqs');
    if (accordionElement) {
      const scrollStep = 150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottom = () => {
    const accordionElement = document.getElementById('faqs');
    if (accordionElement) {
      const scrollStep = -150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const openTerms = () => {
    dispatch(updateFaqsLanguage(false))
    dispatch(updateTermsModal(true))
  }

  useEffect(() => {
    setColumnScroll(document.getElementById("columnScrollFaqs")?.clientHeight)
  }, [columnScroll])

  useEffect(() => {
    const handleScroll = () => {
      const scrollDiv = document.getElementById('faqs');
      if (scrollDiv && hasVerticalScrollbar(scrollDiv)) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    const scrollDiv = document.getElementById('faqs');

    if (scrollDiv) {
      scrollDiv.addEventListener('faqs', handleScroll);
      resizeObserver.observe(scrollDiv);

      return () => {
        scrollDiv.removeEventListener('faqs', handleScroll);
        resizeObserver.unobserve(scrollDiv);
      };
    }
  }, [faqs]);

  useEffect(() => {
    dispatch(fetchFaqs())
      .then((res) => {
        if (res.payload.ResultCode === 1) {
          setFaqs(res.payload?.Data);
        }
        dispatch(updateLoading(false))
      })
      .catch((error) => {
        dispatch(updateLoading(false))
        dispatch(updateFaqsLanguage(false))
      })
  }, [dispatch])

  useEffect(() => {
    dispatch(updateLoading(true));
  }, [])


  return (
    <Row className='h-100 m-0 weight-300'>
      <Power style={{ width: "250px", height: "auto", bottom: 40, left: "calc(50% - 125px)",fill:colors.font }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: "15%" }}>
        <Row className='h-fit-content'>
          <span className='m-0 p-0 text-2 w-100 text-center'>{t(`app.helpAndFaqs`)}</span>
          <span className='m-0 p-0 text-5 w-100 text-center mt-4' style={{ textDecoration: "underline" }} onClick={() => openTerms()}>{t('app.termsAndConditions')}</span>
        </Row>
      </Col>
      <Col xs="12" style={{ height: "60%" }}>
        <>
          <Row style={{ height: "20%" }} className="m-0">
            {translationFlags.map((item, index) =>
              <Col key={index} xs="3" onClick={() => changeCountry(item)} className="d-flex justify-content-center">
                <div className='m-0 p-0' style={{ width: "130px", height: "130px" }}>
                  <CustomCard color={i18n.language === item?.code ? "dark" : "secondary"}>
                    <div className='m-0 p-0' style={{ width: "100px" }}>{item?.flag}</div>
                  </CustomCard>
                </div>
              </Col>
            )}
          </Row>
          <Row style={{ height: "72.5%", marginTop: "10%" }} id="columnScrollFaqs">
            <Col xs="12">
              <Row className='h-100 position-relative d-flex justify-content-center'>
                {hasScroll &&
                  <>
                    <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, top: -110, transform: "rotate(180deg)", stroke: colors.font }} onClick={() => scrollToBottom()}>
                      <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}}/>
                    </div>
                    <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, bottom: -110, stroke: colors.font }} id="scrollDown" onClick={() => scrollToTop()}>
                      <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}} />
                    </div>
                  </>
                }
                <Accordion flush open={open} toggle={toggle} className={`overflow-auto customScroll ${hasScroll && "pe-3"}`} id="faqs" style={{ height: columnScroll }}>
                  {
                    faqs && faqs.map((item, index) => (
                      <AccordionItem className={`${index === 0 ? "mb-4" : index === faqs?.length - 1 ? "mt-4" : "my-4"} px-3`} key={index}>
                        <AccordionHeader targetId={`${item?.id}`}>
                          <Col xs="11">
                            <Row>
                              <span className='text-5'>
                                {item?.title[i18n.language]}
                              </span>
                            </Row>
                          </Col>
                          <Col xs="1">
                            <Row className='d-flex justify-content-end align-items-center'>
                              <ChevronDown style={{ width: "47px", height: "47px", fill: colors.font }} />
                            </Row>
                          </Col>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${item?.id}`}>
                          <Row className='px-3'>
                            <span className='m-0 p-0 text-5' style={{ textAlign: "justify" }}>
                              {item?.text[i18n.language]}
                            </span>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    ))
                  }
                </Accordion>
              </Row>
            </Col>
          </Row>
        </>
      </Col>
      <Col xs="12" style={{ height: "25%" }}>
        <Row className='h-100 d-flex flex-column'>
          <Col xs="12" className='mb-4' style={{ height: "130px" }} />
          <Col xs="12" className='mt-4'>
            {
              faqs &&
              <CustomButton
                color="secondary"
                label={t('app.back')}
                textSize="text-3 weight-300"
                modal
                timer
                onClick={() => dispatch(updateFaqsLanguage(false))}
                id="modals"
              />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FaqsModal;
