import React, { Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import VerticalLayout from "layout/verticalLayout";

const Home = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const CollectNumber = React.lazy(() => import(/* webpackChunkName: "views-collect-number" */ "views/collect-number"));
const CollectPin = React.lazy(() => import(/* webpackChunkName: "views-collect-pin" */ "views/collect-pin"));
const AccessLockers = React.lazy(() => import(/* webpackChunkName: "views-acess-lockers" */ "views/access-lockers"));
const OpenLocker = React.lazy(() => import(/* webpackChunkName: "views-open-locker" */ "views/open-locker"));
const ReleaseLocker = React.lazy(() => import(/* webpackChunkName: "views-release-locker" */ "views/release-locker"));
const CheckoutSummary = React.lazy(() => import(/* webpackChunkName: "views-checkout-summary" */ "views/checkout-summary"));
const Checkout = React.lazy(() => import(/* webpackChunkName: "views-checkout" */ "views/checkout"));
const ConfirmRental = React.lazy(() => import(/* webpackChunkName: "views/confirm-rental" */ "views/confirm-rental"));


const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <VerticalLayout>
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route exact path="/home">
              <Route index element={<Home />} />
            </Route>
            <Route exact path="/collect-number">
              <Route index element={<CollectNumber />} />
            </Route>
            <Route exact path="/collect-pin">
              <Route index element={<CollectPin />} />
            </Route>
            <Route exact path="/access-lockers">
              <Route index element={<AccessLockers />} />
            </Route>
            <Route exact path="/open-locker">
              <Route index element={<OpenLocker />} />
            </Route>
            <Route exact path="/checkout-summary">
              <Route index element={<CheckoutSummary />} />
            </Route>
            <Route exact path="/checkout">
              <Route index element={<Checkout />} />
            </Route>
            <Route exact path="/release-locker">
              <Route index element={<ReleaseLocker />} />
            </Route>
            <Route exact path="/confirm-rental">
              <Route index element={<ConfirmRental />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </VerticalLayout>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
