import React from 'react';
import ReactDom from 'react-dom';
import { colors } from 'config/colors';

const styles = {
  container: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '50',
    height: '85%',
    width: '100%',
    backgroundColor: colors.white,
  }
}

export const Portal = ({ children, isOpen }) => {

  if (isOpen === false) return null;

  return ReactDom.createPortal(
    <div style={styles.container}>
      <div className='m-0 w-100 h-100' style={{padding:"100px"}}>
        {children}
      </div>
    </div>,
   document.getElementById('content-container') ? document.getElementById('content-container') : document.body
  )
}