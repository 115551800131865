import React from 'react';
import { Row, Col } from 'reactstrap';
import { updateModalLanguage } from 'store/apps/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { translationFlags } from 'config/translationFlags';
import CustomCard from 'components/common/cards/customCard';
import CustomButton from 'components/common/buttons/customButton';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import { colors } from 'config/colors';

const LanguageModal = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = useSelector(state => state?.appConfig?.language);

  const changeCountry = (country) => {
    i18n.changeLanguage(country?.code);
    dispatch(updateModalLanguage(false))
  }

  return (
    <Row className='m-0 h-100 w-100 weight-300'>
      <Power style={{ width: "250px", height: "auto", bottom: 40, left: "calc(50% - 125px)", fill: colors.font }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: "15%" }}>
        <Row className='h-100'>
          <span className='m-0 p-0 text-2 w-100 text-center'>{t('app.changeLanguage')}</span>
        </Row>
      </Col>
      <Col xs="12" style={{ height: "60%" }}>
        <Row>
          {translationFlags.map((item, index) =>
            <Col key={index} xs="12" className='my-4' onClick={() => changeCountry(item)}>
              <CustomCard color={language === item?.code ? "dark" : "secondary"}>
                <div className="m-0 p-0 p-5 w-100 h-fit-content d-flex flex-row">
                  <div className='m-0 p-0' style={{ width: "75px" }}>{item?.flag}</div>
                  <div className='m-0 p-0 d-flex align-items-center ps-5 text-4' style={{ fontSize: "1.5em", whiteSpace: "nowrap" }}>{item?.language}</div>
                </div>
              </CustomCard>
            </Col>
          )}
        </Row>
      </Col>
      <Col xs="12" style={{ height: "25%" }}>
        <Row className='h-100 d-flex flex-column'>
          <Col xs="12" className='mb-4' style={{ height: "130px" }} />
          <Col xs="12" className='mt-4'>
            <CustomButton color="secondary" label={t('app.back')} timer modal textSize="text-3 weight-300" onClick={() => dispatch(updateModalLanguage(false))} id="modals" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default LanguageModal;
