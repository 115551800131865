import React, { useState, useEffect } from 'react';
import { Row, Button, Col } from 'reactstrap';
import { colors } from 'config/colors';
import { routes, modals } from 'config/appConfig';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomButton = ({ color, label, onClick, textSize, disabled, buttonSize, portal, id, timer, name, modal, icon }) => {

  const [isPressed, setIsPressed] = useState(false);
  const [isTiming, setIsTiming] = useState(timer ? true : false);
  const { pathname } = useLocation();
  const url = pathname;
  const [delay, setDelay] = useState(timer ? true : false);
  const [timeLeft, setTimeLeft] = useState(false);
  const navigate = useNavigate();
  let { handleTransition } = useRouterTransition(navigate);
  const [isInsideButton, setInsideButton] = useState(false);
  const location = useLocation();
  const { languageModalOpen, faqsModalOpen, notificationsModalOpen, loading, termsModalOpen } = useSelector(state => state?.appConfig);


  const detectActivity = () => {
    document.addEventListener('touchstart', resetInactivityTimer);
    return () => {
      removeActivityListeners()
    }
  };

  const removeActivityListeners = () => {
    document.removeEventListener('touchstart', resetInactivityTimer);
  };

  const resetInactivityTimer = (event) => {
    animationTimer();
  };

  const handleTouchStart = () => {
    setIsPressed(true);
  }

  const handleTouchEnd = () => {
    if (isInsideButton) {
      setIsTiming(false);
    }
    setIsPressed(false);
  };

  const handleTouchMove = (event) => {
    if (timer) {
      const touch = event.touches[0];
      const touchX = touch.clientX;
      const touchY = touch.clientY;
      const buttonRect = event.touches[0]?.target.getBoundingClientRect();

      if (touchX < buttonRect.left || touchX > buttonRect.right || touchY < buttonRect.top || touchY > buttonRect.bottom) {
        setInsideButton(false)
      }
    }
  };

  const navTo = (url) => {
    handleTransition(url)
  };

  const animationTimer = () => {
    let delay = false;
    if (modal) {
      modals.map((item) => {
        if (languageModalOpen) {
          if ("language" === item?.name) {
            delay = item.inativityTime;
            setDelay(delay * 10);
            setTimeLeft(0);
          }
        }
        if (faqsModalOpen) {
          if ("faqs" === item?.name) {
            delay = item.inativityTime;
            setDelay(delay * 10);
            setTimeLeft(0);
          }
        }
        if (notificationsModalOpen) {
          if ("notifications" === item?.name) {
            delay = item.inativityTime;
            setDelay(delay * 10);
            setTimeLeft(0);
          }
        }
        if (termsModalOpen) {
          if ("terms" === item?.name) {
            delay = item.inativityTime;
            setDelay(delay * 10);
            setTimeLeft(0);
          }
        }
      })
    }
    else {
      routes.map((item) => {
        if (url === item.path) {
          delay = item.inativityTime;
          setDelay(delay * 10);
          setTimeLeft(0);
          return;
        }
      });
    }
  };

  useEffect(() => {
    let id;
    if (isTiming) {
      if (timeLeft < delay) {
        if (!loading && !isPressed) {
          id = setInterval(() => {
            setTimeLeft(timeLeft + 1);
          }, 100);
        }
      }
      if (timeLeft === delay) {
        setIsPressed(true);
        setIsTiming(false);
        navTo('/home')
        if (!modal) {
          setTimeout(() => {
            setIsPressed(false);
          }, 100);
        }
      }
    }
    return () => clearInterval(id)
  }, [isTiming, timeLeft, delay, loading, isPressed, modal]);

  useEffect(() => {
    if (modal && timeLeft === delay && location?.pathname === "/home") {
      setIsPressed(false);
      setTimeout(() => {
        onClick();
      }, 100);
    }
  }, [modal, timeLeft, location, delay])


  useEffect(() => {
    detectActivity()
    resetInactivityTimer()
    return () => {
      removeActivityListeners()
    }
  }, [])

  return (
    <Row className='border-radius' id={id}>
      <Col xs="12" id={id}>
        <Row className='border-radius position-relative overflow-hidden' style={{ height: buttonSize === "small" ? "105px" : "130px" }} id={id}>
          <Button
            disabled={disabled}
            color={color}
            name={name}
            className={` ${textSize} border-0 border-radius d-flex justify-content-center align-items-center position-relative w-100`}
            style={{ height: disabled ? "100%" : buttonSize === "small" ? "97px" : "122px", transform: isPressed ? 'translateY(8px)' : 'translateY(0)', transition: "0.1s", zIndex: 3 }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onClick={onClick}
            id={id}
          >
            <span className='m-0 p-0 w-100 h-fit-content position-relative' id={id}>
              {icon && <span className='position-absolute' style={{ left: 15 }}>
                {icon}
              </span>}
              {label}
            </span>
          </Button>
          <div className='position-absolute border-radius'
            id={id}
            style={{
              zIndex: 1,
              bottom: "0px",
              height: disabled ? "0px" : buttonSize === "small" ? "97px" : "122px",
              background: color === "secondary" ? portal ? colors?.secondaryButton_shaddow : colors["cream"]?.dark : colors?.primary_shadow,
            }}
          />
          {(isTiming && delay && timer && color !== "primary") && (
            <div
              className='position-absolute border-radius'
              id="timer"
              style={{
                zIndex: 2,
                bottom: '0px',
                height: '8px',
                background: colors?.font,
                width: "100%",
                left: `-${(timeLeft / delay) * 100}%`,
                transition: "left 0.1s linear",
              }}
            />
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default CustomButton;
