import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { updateFaqsLanguage, updateLoading, updateTermsModal } from 'store/apps/appConfig';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/buttons/customButton';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ChevronDownScroll } from 'assets/images/icons/chevronDownScroll.svg'
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import { colors } from 'config/colors';
import { termsAndConditions } from 'store/apps/general';

const TermsModal = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [columnTermsScroll, setColumnTermsScroll] = useState(false);
  const [hasTermsScroll, setHasTermsScroll] = useState(false);
  const [text, setText] = useState(false);
  const [textGroup, setTextGroup] = useState(false);
  const { language } = useSelector(state => state?.appConfig);

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  const choiceText = (text) => {
    if (language === 'pt') {
      setText(text?.pt)
    }
    else if (language === 'en') {
      setText(text?.en)
    }
    else if (language === 'es') {
      setText(text?.es)
    }
    else {
      setText(text?.fr)
    }
  }

  const scrollTermsToTop = () => {
    const termsElement = document.getElementById('terms');
    if (termsElement) {
      const scrollStep = - 150;
      const currentPosition = termsElement.scrollTop;
      termsElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const scrollToTermsBottom = () => {
    const termsElement = document.getElementById('terms');
    if (termsElement) {
      const scrollStep = 150;
      const currentPosition = termsElement.scrollTop;
      termsElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const closeTermsModal = () => {
    dispatch(updateTermsModal(false));
    dispatch(updateFaqsLanguage(true));
  }

  useEffect(() => {
    setColumnTermsScroll(document.getElementById("columnScrollTerms")?.clientHeight)
  }, [columnTermsScroll])


  useEffect(() => {
    const handleScrollTerms = () => {
      const scrollTermsDiv = document.getElementById('terms');
      if (scrollTermsDiv && hasVerticalScrollbar(scrollTermsDiv)) {
        setHasTermsScroll(true);
      } else {
        setHasTermsScroll(false);
      }
    };

    const resizeTermsObserver = new ResizeObserver(handleScrollTerms);
    const scrollTermsDiv = document.getElementById('terms');

    if (scrollTermsDiv) {
      scrollTermsDiv.addEventListener('terms', handleScrollTerms);
      resizeTermsObserver.observe(scrollTermsDiv);

      return () => {
        scrollTermsDiv.removeEventListener('terms', handleScrollTerms);
        resizeTermsObserver.unobserve(scrollTermsDiv);
      };
    }
  }, [text]);

  useEffect(() => {
    dispatch(termsAndConditions()).then((res) => {
      setTextGroup(res?.payload?.Data?.text);
      dispatch(updateLoading(false));
    })
    .catch((error) => {
      dispatch(updateLoading(false))
    })
  }, [dispatch])

  useEffect(() => {
    choiceText(textGroup)
  }, [language, textGroup])

  useEffect(() => {
    dispatch(updateLoading(true));
  }, [])


  return (
    <Row className='h-100 m-0 weight-300'>
      <Power style={{ width: "250px", height: "auto", bottom: 40, left: "calc(50% - 125px)",fill:colors.font }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: "12.5%" }}>
        <Row className='h-fit-content'>
          <span className='m-0 p-0 text-2 w-100 text-center'>{t(`app.termsAndConditions`)}</span>
        </Row>
      </Col>
      <Col xs="12" style={{ height: "65%" }} className="position-relative">

        <Row className='h-100' id="columnScrollTerms">
          {hasTermsScroll &&
            <>
              <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center w-100' style={{ width: 100, height: 100, top: -100, transform: "rotate(180deg)", stroke: colors.font }} onClick={() => scrollTermsToTop()}>
                <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}}/>
              </div>
              <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center w-100' style={{ width: 100, height: 100, bottom: -100, stroke: colors.font }} id="scrollDown" onClick={() => scrollToTermsBottom()}>
                <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}}/>
              </div>
            </>
          }
          <Col xs="12" className='h-100' style={{ border: `3px solid ${colors.terra}`, height: columnTermsScroll }}>
            <Row className='h-100 p-1'>
              <div className="m-0 p-0 h-100 text-5 overflow-scroll noScroll" id="terms">
                <span className='m-0 p-0 w-100 h-100'>
                  <div dangerouslySetInnerHTML={{ __html: text }} />
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs="12" style={{ height: "22.5%" }}>
        <Row className='h-100 d-flex flex-column'>
          <Col xs="12" className='mb-4' style={{ height: "95px" }} />
          <Col xs="12" className='mt-4'>
            {
              text &&
              <CustomButton
                color="secondary"
                label={t('app.back')}
                textSize="text-3 weight-300"
                modal
                timer
                onClick={() => closeTermsModal()}
                id="modals"
              />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TermsModal;
