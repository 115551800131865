import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'helpers/api'

export const updateLanguage = createAsyncThunk('appConfig/updateLanguage', async (params) => {
  return params;
})

export const updateModalLanguage = createAsyncThunk('appConfig/updateModalLanguage', async (params) => {
  return params;
})

export const updateFaqsLanguage = createAsyncThunk('appConfig/updateFaqsLanguage', async (params) => {
  return params;
})

export const updateTermsModal = createAsyncThunk('appConfig/updateTermsModal', async (params) => {
  return params;
})

export const updateLoading = createAsyncThunk('appConfig/updateLoading', async (params) => {
  return params;
})

export const updateNotificationsModal = createAsyncThunk('appConfig/updateNotificationsModal', async (params) => {
  return params;
})

export const updateSocketStatus = createAsyncThunk('appConfig/updateSocketStatus', async (params) => {
  return params;
})

export const updateRentalLockerQrCode = createAsyncThunk('appConfig/rentalLockerQrCode', async (params) => {
  return params;
})

export const fetchFaqs = createAsyncThunk('appConfig/fetchFaqs', async (params, { getState, dispatch }) => {
  const response = await api.get('api/faqs/list')
  return response?.data
})

export const appConfigSlice = createSlice({
  name: 'theme',
  initialState: {
    language: "pt",
    languageModalOpen: false,
    notificationsModalOpen: false,
    termsModalOpen:false,
    faqsModalOpen: false,
    loading: false,
    rentalLockerQrCode: false,
    socket: {
      active: false,
    },
  },

  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.language = action.payload
    })
    builder.addCase(updateModalLanguage.fulfilled, (state, action) => {
      state.languageModalOpen = action.payload
    })
    builder.addCase(updateNotificationsModal.fulfilled, (state, action) => {
      state.notificationsModalOpen = action.payload
    })
    builder.addCase(updateFaqsLanguage.fulfilled, (state, action) => {
      state.faqsModalOpen = action.payload
    })
    builder.addCase(updateTermsModal.fulfilled, (state, action) => {
      state.termsModalOpen = action.payload
    })
    builder.addCase(updateLoading.fulfilled, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(updateRentalLockerQrCode.fulfilled, (state, action) => {
      state.rentalLockerQrCode = action.payload
    })
    builder.addCase(updateSocketStatus.fulfilled, (state, action) => {
      state.socket = {
        active: action.payload
      }
    })
  }
})

export default appConfigSlice.reducer
