import React, { useState, useEffect } from 'react';
import { Row, Col, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDown } from 'assets/images/icons/chevronDown.svg';
import { ReactComponent as ChevronDownScroll } from 'assets/images/icons/chevronDownScroll.svg'
import { updateLoading } from 'store/apps/appConfig';
import { deleteUserNotification, readUserNotification, fetchUserNotifications } from 'store/apps/notifications';
import CustomButton from '../buttons/customButton';
import { notifications } from 'config/notifications';
import { updateNotificationsModal } from 'store/apps/appConfig';
import { ReactComponent as Power } from 'assets/images/logos/PoweredByLokk.svg';
import { colors } from 'config/colors';
import { ReactComponent as Trash } from 'assets/images/icons/trash.svg';


const NotificationsModal = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState('');
  const [columnScroll, setColumnScroll] = useState(false);
  const [hasScroll, setHasScroll] = useState(false);
  const [deleteErrorId, setDeleteErrorId] = useState(false);
  const numberPrefix = useSelector(state => state.user.prefix);
  const number = useSelector(state => state.user.number);
  const notificationsInfo = useSelector(state => state?.notifications?.Data);

  const toggle = (id) => {
    setOpen(prevOpen => (prevOpen === id ? '' : id));
  };

  const readNotification = (id, rowId) => {
    dispatch(readUserNotification({
      number: number,
      numberPrefix: numberPrefix,
      notificationId: id,
      readed: 1,
      rowId: rowId
    }))
  }

  const hasVerticalScrollbar = (element) => {
    if (element) {
      return element.scrollHeight > element.clientHeight;
    }
  }

  const deleteNotification = (e, rowId) => {
    e.stopPropagation();
    dispatch(updateLoading(true));
    if(!deleteErrorId){
      dispatch(deleteUserNotification(rowId))
        .then((res) => {
          if (res?.payload?.ResultCode === 1) {
            dispatch(fetchUserNotifications({ number: number, numberPrefix: numberPrefix }))
              .then(() => {
                dispatch(updateLoading(false));
              })
          }
          else {
            dispatch(updateLoading(false));
            setDeleteErrorId(rowId);
          }
        })
        .catch((error) => {
          dispatch(updateLoading(false))
        })
        
    }
  }

  const scrollToTop = () => {
    const accordionElement = document.getElementById('accordion');
    if (accordionElement) {
      const scrollStep = 150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  const scrollToBottom = () => {
    const accordionElement = document.getElementById('accordion');
    if (accordionElement) {
      const scrollStep = -150;
      const currentPosition = accordionElement.scrollTop;
      accordionElement.scrollTo({
        top: currentPosition + scrollStep,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    setColumnScroll(document.getElementById("columnScrollNotifications")?.clientHeight)
  }, [columnScroll])

  useEffect(() => {
    const handleScroll = () => {
      const scrollDiv = document.getElementById('accordion');
      if (scrollDiv && hasVerticalScrollbar(scrollDiv)) {
        setHasScroll(true);
      } else {
        setHasScroll(false);
      }
    };

    const resizeObserver = new ResizeObserver(handleScroll);
    const scrollDiv = document.getElementById('accordion');

    if (scrollDiv) {
      scrollDiv.addEventListener('accordion', handleScroll);
      resizeObserver.observe(scrollDiv);

      return () => {
        scrollDiv.removeEventListener('accordion', handleScroll);
        resizeObserver.unobserve(scrollDiv);
      };
    }
  }, [notificationsInfo]);

  useEffect(() => {
    if (deleteErrorId) {
      setTimeout(() => {
        setDeleteErrorId(false);
      }, [2000])
    }
  }, [deleteErrorId])

  return (
    <Row className='h-100 weight-300'>
      <Power style={{ width: "250px", height: "auto", bottom: 40, left: "calc(50% - 125px)",fill:colors.font }} className="m-0 m p-0 position-absolute" />
      <Col xs="12" style={{ height: "12.5%" }}>
        <Row className='h-100'>
          <span className='m-0 p-0 text-2 w-100 text-center'>{t('app.notifications')}</span>
        </Row>
      </Col>
      <Col xs="12" style={{ height: "62.5%" }} id="columnScrollNotifications">
        <Row className='h-100 d-flex justify-content-center pt-4'>
          {hasScroll &&
            <>
              <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, top: 195, transform: "rotate(180deg)" }} onClick={() => scrollToBottom()}>
                <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}}/>
              </div>
              <div className='m-0 p-0 position-absolute d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, bottom: 325 }} id="scrollDown" onClick={() => scrollToTop()}>
                <ChevronDownScroll className='m-0 p-0 w-100 h-100' style={{stroke: colors.font}}/>
              </div>
            </>
          }
          {
            notificationsInfo?.length > 0 ?
              <Accordion flush open={open} toggle={toggle} className={`overflow-auto customScroll ${hasScroll ? "pe-3" : ""}`} id="accordion" style={{ height: columnScroll }}>
                {
                  notificationsInfo && notificationsInfo.map((item, index) => (
                    <div
                    key={index}
                      className={`${index === 0 ? "mb-3" : index === notificationsInfo?.length - 1 ? "mt-3" : "my-3"} ${deleteErrorId === item?.rowId ? "error" : ""}`}
                      style={{ borderRadius: 10, border: `2px solid ${deleteErrorId === item?.rowId ? colors.error : "transparent"}` }}
                    >
                      <AccordionItem
                        onClick={!item?.readed ? () => readNotification(item?.id, item?.rowId) : null}>
                        <AccordionHeader targetId={`${index + 1}`}>
                          <Col xs="10">
                            <Row className='d-flex align-items-center ps-2'>
                              <span className='m-0 p-0 w-fit-content text-4' style={{ color: notifications[item?.type] }}>{item?.readed ? "○" : "●"}</span>
                              <span className='m-0 w-fit-content p-0 ps-3 text-5' style={{ overflowWrap: "break-word" }}>
                                {item?.subject[i18n.language]}
                              </span>
                            </Row>
                          </Col>
                          <Col xs="2">
                            <Row className='d-flex justify-content-end align-items-center pe-3'>
                              <Trash style={{ width: "30", height: "30",stroke: colors.font }} className="m-0 p-0 me-4" onClick={(e) => deleteNotification(e, item?.rowId)} />
                              <ChevronDown style={{ width: "25px", fill: colors.font, height: "25px", transform: `rotate(${open === `${index + 1}` ? "180deg" : "0"})`, transition: "0.3s all" }} className="m-0 p-0 ms-2" />
                            </Row>
                          </Col>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index + 1}`}>
                          <Row className='px-2'>
                            <span className='m-0 p-0 text-5' style={{ wordBreak: "break-word" }}>
                              {item?.text[i18n.language]}
                            </span>
                          </Row>
                        </AccordionBody>
                      </AccordionItem>
                    </div>
                  ))
                }
              </Accordion>
              :
              <Col xs="12" className='pt-5'>
                <Row className='mt-3 h-100 d-flex align-items-center'>
                  <span className='h-auto m-0 p-0 text-5 text-center'>{t('app.noNotifications')}.</span>
                </Row>
              </Col>
          }
        </Row>
      </Col>
      <Col xs="12" style={{ height: "25%" }}>
        <Row className='h-100 d-flex flex-column'>
          <Col xs="12" className='mb-4' style={{ height: "130px" }} />
          <Col xs="12" className='mt-4'>
            <CustomButton color="secondary" label={t('app.back')} timer textSize="text-3 weight-300" modal onClick={() => dispatch(updateNotificationsModal(false))} id="modals" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NotificationsModal;
